<template>
  <div>
    <v-container>
      <h1 style="text-align: left;margin-bottom: 20px">{{ $t('media') }}</h1>
      <div v-if="!$vuetify.breakpoint.mobile">
        <div class="ten_news_item" v-for="(d,i) in newsList" :key="i" style="cursor: pointer" @click="gotoDetail(d)">
          <div style="text-align: left">
            <span class="ten_tagline">{{ d.createdAt | formatDate }}</span>
            <h1 v-html="cutText(getTranslate(d.title), 250)"></h1>
            <p v-html="cutText(getTranslate(d.body), 500)"></p>
          </div>
          <div>
            <div v-if="d.iframeLive" v-html="d.iframeLive"></div>
            <v-img v-if="!d.iframeLive" :src="d.url ||  (d.urlList ?  d.urlList[0] : '')"
                   style="width: 480px;height: 320px" contain></v-img>
          </div>
        </div>
      </div>
      <div v-if="$vuetify.breakpoint.mobile">
        <div class="bBottom" v-for="(d,i) in newsList" :key="i" style="cursor: pointer" @click="gotoDetail(d)">
          <div style="text-align: left">
            <span class="ten_tagline">{{ d.createdAt | formatDate }}</span>
            <h1 v-html="cutText(getTranslate(d.title), 250)"></h1>
            <p v-html="cutText(getTranslate(d.body), 500)"></p>
          </div>
          <div>
            <div v-if="d.iframeLive" v-html="d.iframeLive"></div>

            <v-img v-if="!d.iframeLive" :src="d.url ||  (d.urlList ?  d.urlList[0] : '')"
                   style="width: 480px;height: 320px" contain></v-img>
          </div>
        </div>
      </div>

    </v-container>
  </div>
</template>

<script>
import MyMixin from "@/mixins/mixin"
import {Constants} from "@/libs/constant";
import axios from "axios";

export default {
  name: "media",
  mixins: [MyMixin],
  data() {
    return {
      newsList: []
    }
  },
  methods: {
    cutText(d, length) {
      if (!d) return "";
      let po = d.length > length ? "<span style='color: blue'> See More...</span>" : "";
      return (d.substr(0, length)) + po;
    },
    gotoDetail(doc) {
      this.$router.push({name: 'NewsEventsDetail', params: {doc: doc}});
    },
    fetchMedia() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getMedia;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.newsList = res.data.data;
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
  },
  created() {
    let vm = this;
    //vm.newsList = vm.getNews().slice(0, 100);
    vm.fetchMedia();
  }
}
</script>


<style scoped>
.ten_news_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0px;
  padding: 10px 0;
  border-bottom: 1px solid #f4f4f4;
}
</style>